export default {
    data() {
        return {
            addressChangedCallback: null
        };
    },
    methods: {
        async loadGooleMapScript(callback) {
            window.initAddressEdit = this.initAddressEdit;
            const api_key = (window.INJECT_ENV && window.INJECT_ENV.GOOGLE_MAP_API_KEY) || "AIzaSyCw305DNDiYvcb3Q2SHYagka_RV5fYAxyw";
            const script = `https://maps.googleapis.com/maps/api/js?key=${api_key}&libraries=places&callback=initAddressEdit&language=en`;

            let scripts = document.querySelectorAll(`script`);
            let existScript = [...scripts].find(s => s.src == script);
            if (existScript) {
                this.addressChangedCallback = callback;
                this.initAddressEdit();
            } else {
                let scriptEle = document.createElement("script");
                scriptEle.async = true;
                scriptEle.src = script;
                document.head.appendChild(scriptEle);
            }
        },
        fillInAddress(autocomplete) {
            const place = autocomplete.getPlace();
            let address = "";
            let city = "";
            let state = "";
            let zip = "";
            for (const component of place.address_components) {
                const componentType = component.types[0];

                switch (componentType) {
                    case "street_number": {
                        address = `${component.long_name} ${address}`;
                        break;
                    }

                    case "route": {
                        address += component.short_name;
                        break;
                    }

                    case "locality":
                        city = component.long_name;
                        break;

                    case "sublocality_level_1":
                        city = component.long_name;
                        break;

                    case "administrative_area_level_1":
                        state = component.long_name;
                        break;

                    case "postal_code":
                        zip = component.long_name;
                        break;
                }
            }
            this.fillAddressFields({ city, address, state, zip });
        },
        fillInCity(autocomplete) {
            const place = autocomplete.getPlace();
            const record = this.$refs["addressEdit"].newRecord;
            let city = "";
            for (const component of place.address_components) {
                if (component.types[0] === "locality") {
                    city = component.long_name;
                }
            }
            record.city = city;
        },
        initAddressAutoComplete() {
            const input = document.querySelector('[data-vv-name="address"]');
            const options = {
                componentRestrictions: { country: "us" },
                fields: ["address_components", "geometry"],
                types: ["address"]
            };
            const autocomplete = new google.maps.places.Autocomplete(input, options);
            autocomplete.addListener("place_changed", () => {
                this.fillInAddress(autocomplete);
                this.addressChangedCallback && this.addressChangedCallback();
            });
        },
        initCityAutoComplete() {
            const input = document.querySelector('[data-vv-name="city"]');
            const options = {
                componentRestrictions: { country: "us" },
                fields: ["address_components", "geometry"],
                types: ["(cities)"]
            };
            const autocomplete = new google.maps.places.Autocomplete(input, options);
            autocomplete.addListener("place_changed", () => {
                this.fillInCity(autocomplete);
                this.addressChangedCallback && this.addressChangedCallback();
            });
        },
        initAddressEdit() {
            this.initAddressAutoComplete();
            this.initCityAutoComplete();
        }
    }
};
