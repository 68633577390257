<template>
  <div class="add-on-items-modal">
    <md-dialog ref="addOnItems" @close="handleClose">
      <md-button class="md-icon-button close" @click="closeModal">
        <md-icon>close</md-icon>
      </md-button>
      <md-dialog-title>Add-On Item Edit</md-dialog-title>
      <md-dialog-content class="center">
        <product-add-on-items-edit :product="order" mode="order"></product-add-on-items-edit>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import ProductAddOnItemsEdit from "@/components/Product/ProductAddOnItemsEdit";
export default {
    data() {
        return {
            order: {}
        };
    },
    methods: {
        open(order) {
            this.order = order;
            this.$refs["addOnItems"].open();
        },
        closeModal() {
            this.$refs["addOnItems"].close();
        },
        handleClose() {
            this.order.product.addOnItems = this.order.addOnItems;
        }
    },
    components: {
        ProductAddOnItemsEdit
    }
};
</script>
<style lang="less">
.add-on-items-modal {
    .mw200 {
        /* max-width: 300px; */
        margin-right: 20px;
        margin-bottom: 20px;
        overflow: hidden;
    }

    .close {
        right: -7px;
        position: absolute !important;
    }
}
</style>
