<template>
  <div>
    <md-table-card class="list" style="width:100%;">
      <md-card-header v-if="mode !== 'order'">
        <h1 class="md-title center">Add-on Items</h1>
      </md-card-header>
      <md-table>
        <md-table-header>
          <md-table-row>
            <md-table-head>Name</md-table-head>
            <md-table-head>Price</md-table-head>
            <md-table-head>Unit</md-table-head>
            <md-table-head v-if="mode === 'order'">Quantity</md-table-head>
            <md-table-head v-if="mode!=='order'">Min Quantity</md-table-head>
            <md-table-head v-if="mode!=='order'">Max Quantity</md-table-head>
            <md-table-head v-if="mode!=='order'">Active</md-table-head>
          </md-table-row>
        </md-table-header>
        <md-table-body>
          <md-table-row v-for="item in product.addOnItems" :key="item._id">
            <md-table-cell>
              <md-input-container>
                <md-input v-model="item.name" :readonly="mode === 'order'"></md-input>
              </md-input-container>
            </md-table-cell>
            <md-table-cell>
              <md-input-container>
                <md-input v-model="item.price" type="number"></md-input>
              </md-input-container>
            </md-table-cell>
            <md-table-cell>
              <md-input-container>
                <md-input v-model="item.unit" :readonly="mode === 'order'"></md-input>
              </md-input-container>
            </md-table-cell>
            <md-table-cell v-if="mode === 'order'">
              <md-input-container>
                <md-input v-model="item.quantity" type="number" :min="item.minQuantity ||0" :max="item.maxQuantity"></md-input>
              </md-input-container>
            </md-table-cell>
            <md-table-cell v-if="mode !== 'order'">
              <md-input-container>
                <md-input v-model="item.minQuantity" type="number"></md-input>
              </md-input-container>
            </md-table-cell>
            <md-table-cell v-if="mode !== 'order'">
              <md-input-container>
                <md-input v-model="item.maxQuantity" type="number"></md-input>
              </md-input-container>
            </md-table-cell>
            <md-table-cell v-if="mode!=='order'">
              <md-switch v-model="item.active"></md-switch>
            </md-table-cell>
            <md-table-cell v-if="mode !== 'order'">
              <md-button type="button" class="md-icon-button" @click="removeAddOnItem(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table-body>
      </md-table>
      <md-button class="md-primary" v-if="mode != 'order'" @click="addAddOnItem">Add another Add-on Item</md-button>
    </md-table-card>
  </div>
</template>

<script>
export default {
    props: ["product", "mode"],
    data() {
        return {
            addOnItems: []
        };
    },
    methods: {
        addAddOnItem() {
            if (!this.product.addOnItems) {
                this.$set(this.product, "addOnItems", []);
            }
            this.product.addOnItems.push({ price: 0, active: true, quantity: 0 });
        },
        removeAddOnItem(a) {
            this.product.addOnItems = this.product.addOnItems.filter(item => item !== a);
        }
    }
};
</script>
<style lang="less">
</style>
