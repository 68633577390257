<template>
    <div class="order-edit">
        <md-card class="mr10 flex1" v-if="order">
            <form @submit.prevent="confirmStatusChange" autocomplete="off">
                <md-card-header style="display: flex; justify-content: space-between">
                    <div style="display: flex; align-items: center; flex-wrap: wrap">
                        <div class="md-title" v-if="id">OrderNo:{{ order.orderNo }}</div>
                        <div class="md-title" v-if="!id">Add New Booking</div>
                        <md-input-container md-inline style="width: 200px; margin: -10px 0 0 20px">
                            <label for>Staff Assign</label>
                            <user-autocomplete :vendor_id="order.vendor_id || (user.role == 'vendor' ? user._id : undefined)" role="staff" v-model="order.staff_id"></user-autocomplete>
                        </md-input-container>
                    </div>
                    <div style="display: flex">
                        <md-button @click="goBack" style="margin-right: 5px" v-if="!id">Cancel</md-button>
                        <submit icon="save" text="Save"></submit>
                    </div>
                </md-card-header>

                <md-card-content>
                    <div>
                        <h4>Items</h4>
                        <order-items-edit :items="order.items" :vendor="order.vendor_id"></order-items-edit>
                    </div>

                    <div>
                        <h4>Customer Pickup</h4>
                        <md-checkbox v-model="order.pickup">Customer Pickup</md-checkbox>
                    </div>

                    <div v-if="id">
                        <h4>Payment</h4>
                        <md-input-container>
                            <label for>Security Deposit</label>
                            <md-input v-model="order.amount.securityDeposit"></md-input>
                        </md-input-container>
                        <md-input-container>
                            <label for>Delivery</label>
                            <md-input v-model="order.amount.shipping"></md-input>
                        </md-input-container>
                        <md-input-container>
                            <label for>Off-Hour Service Fee</label>
                            <md-input v-model="order.amount.offhour"></md-input>
                        </md-input-container>
                        <md-input-container>
                            <label for>Discount</label>
                            <md-input v-model="order.discount"></md-input>
                        </md-input-container>
                        <md-input-container>
                            <label for>Discount Reason</label>
                            <md-input v-model="order.discountReason"></md-input>
                        </md-input-container>

                        <md-input-container>
                            <label for>Status</label>
                            <md-select v-model="order.status">
                                <md-option :key="ix" :value="o" v-for="(o, ix) in orderStatuses">{{ o }}</md-option>
                            </md-select>
                        </md-input-container>
                        <md-input-container v-if="false">
                            <label for>Payment status</label>
                            <md-select v-model="order.paymentStatus">
                                <md-option :key="ix" :value="o" v-for="(o, ix) in paymentStatuses">{{ o }}</md-option>
                            </md-select>
                        </md-input-container>
                    </div>

                    <div>
                        <h4>Customer</h4>

                        <md-input-container :class="{ 'md-input-invalid': !order.user_id && order.user_email }">
                            <label for>User Id</label>
                            <user-autocomplete @innerValueChanged="handleUserEmailChanged" @userChanged="handleUserChanged" field="email" v-model="order.user_id"></user-autocomplete>

                            <div class="md-error" v-if="!order.user_id">New User will be created</div>
                        </md-input-container>
                        <md-input-container>
                            <label for>User Name</label>
                            <md-input v-model="order.user_name"></md-input>
                        </md-input-container>

                        <md-input-container>
                            <label for>Company Name</label>
                            <md-input v-model="order.user_company"></md-input>
                        </md-input-container>
                    </div>
                    <div>
                        <h4>Address</h4>
                        <md-input-container :key="ix" v-for="(f, ix) in addressFields">
                            <label for>{{ f.heading }}</label>
                            <md-select v-if="f.type == 'select'" v-model="order.address[f.data]" :data-vv-name="f.data">
                                <md-option :key="ix" :value="o" v-for="(o, ix) in f.options">{{ o }}</md-option>
                            </md-select>

                            <md-input :readonly="f.type == 'readonly'" :type="f.type || 'text'" v-else v-model="order.address[f.data]" :data-vv-name="f.data" placeholder=""></md-input>
                        </md-input-container>
                    </div>
                </md-card-content>
                <div style="display: none">
                    <input autocomplete="address-level4" id="PreventChromeAutocomplete" name="PreventChromeAutocomplete" type="text" />
                </div>
            </form>
        </md-card>
        <confirm @ok="save" ref="status-confirm"></confirm>
    </div>
</template>
<script>
import { orderStatuses, paymentStatuses, addressFields, country } from "@/config";
import OrderItemsEdit from "@/components/Order/OrderItemsEdit";
import Submit from "@/components/Submit";
import Confirm from "@/components/modal/Confirm";
import { mapGetters } from "vuex";
import UserAutocomplete from "@/components/UserAutocomplete.vue";
import addressAutoFill from "@/components/Address/AddressAutoFill";

export default {
    props: ["id"],
    components: {
        OrderItemsEdit,
        Submit,
        Confirm,
        UserAutocomplete
    },
    mixins: [addressAutoFill],
    data() {
        return {
            ready: false,
            defaultOrder: null,
            order: null,
            pays: [],
            addressFields,
            distanceFee: 0,
            offhourFee: 0
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user"
        }),
        paymentStatuses() {
            return paymentStatuses;
        },
        orderStatuses() {
            if (!this.pays.length) {
                return orderStatuses;
            }
            // return orderStatuses;
            return orderStatuses.filter(o => o != "Void");
        },
        isOffHour() {
            return (
                this.order &&
                this.order.items.find(i => {
                    if (i.product.rental) {
                        let deliveryTime = +moment(i.rentalStartDate).format("HHmmss");
                        if (deliveryTime < 90000 || deliveryTime > 210000) {
                            return true;
                        }
                        let pickupTime = +moment(i.rentalEndDate).format("HHmmss");
                        if (pickupTime < 90000 || pickupTime > 210000) {
                            return true;
                        }
                    }

                    if (!i.product.rental) {
                        let deliveryTime = +moment(i.rentalStartDate).format("HHmmss");
                        if (deliveryTime < 90000 || deliveryTime > 210000) {
                            return true;
                        }
                    }
                })
            );
        }
    },
    watch: {
        isOffHour: {
            async handler() {
                if (this.ready) {
                    if (this.isOffHour) {
                        this.$set(this.order.amount, "offhour", this.offhourFee);
                    } else {
                        this.$set(this.order.amount, "offhour", 0);
                    }
                }
            }
        }
    },
    methods: {
        // method triggered in mixins
        fillAddressFields(obj) {
            console.info("fill addddr");
            const record = this.order.address;
            this.$set(record, "city", obj.city);
            this.$set(record, "address", obj.address);
            this.$set(record, "state", obj.state);
            this.$set(record, "zip", obj.zip);

            console.info("loadDistanceFee");
            this.loadDistanceFee();
        },
        async getOrder() {
            console.info("getOrder");
            if (this.id) {
                var result = await this.$store.dispatch(`crud/get`, {
                    api: `orders/${this.id}`
                });
                this.defaultOrder = JSON.parse(JSON.stringify(result));
                this.order = result;
            } else {
                let vendor_id = undefined;
                if (this.user.role == "vendor") {
                    vendor_id = this.user._id;
                }
                if (this.user.role == "staff") {
                    vendor_id = this.user.staff_vendor_id;
                }
                this.order = this.order || { vendor_id };
            }

            this.$set(this.order, "items", this.order.items || []);
            this.$set(this.order, "amount", this.order.amount || {});
            this.$set(this.order, "address", this.order.address || { country: country.name });
            setTimeout(() => {
                this.ready = true;
                console.info("loadGooleMapScript");
                this.loadGooleMapScript(() => {});
            }, 0);
        },
        async loadDistanceFee() {
            let result = await this.$store.dispatch("crud/post", {
                api: "shippings/distance-fee",
                data: {
                    vendor_id: this.order.vendor_id,
                    destAddress: this.order.address
                }
            });
            this.distanceFee = result.charge;
            this.$set(this.order.amount, "shipping", result.charge);
        },
        async loadOffHourFee() {
            let result = await this.$store.dispatch("crud/post", {
                api: "shippings/offhour-fee",
                data: {
                    vendor_id: this.order.vendor_id
                }
            });
            this.offhourFee = result.charge;
        },
        async getUsers() {
            var result = await this.$store.dispatch("crud/get", {
                api: "users",
                params: {
                    pageSize: 0
                }
            });
            this.users = result.data || result;
        },
        async getAddress(uid) {
            let result = await this.$store.dispatch("crud/get", {
                api: `addresses`,
                params: {
                    uid: uid
                }
            });
            return result.data;
        },
        async getPays() {
            if (this.id) {
                let result = await this.$store.dispatch("crud/get", {
                    api: `pays`,
                    params: {
                        orderId: this.id
                    }
                });
                this.pays = result.data;
            }
        },
        async matchUserAddress(user) {
            if (user) {
                if (this.defaultOrder && user._id == this.defaultOrder.user_id) {
                    console.info("same user", user, this.defaultOrder.user_id);
                    let copyAddress = JSON.parse(JSON.stringify(this.defaultOrder.address));
                    this.$set(this.order, "address", copyAddress);
                } else {
                    let addresses = await this.getAddress(user._id);
                    if (addresses.length) {
                        var address = addresses[0];
                        console.info("new user", address);
                        if (address) {
                            this.$set(this.order, "address", address);
                        }
                    } else {
                        console.info("no address");
                        this.$set(this.order, "address", {});
                    }
                }
            }
        },
        async save() {
            if (this.order) {
                if (this.order.status == "Completed") {
                    this.$store.commit("setMessage", "you should not edit the order that is completed");
                    return;
                }

                // check user
                if (!this.order.user_email || !this.order.user_name) {
                    this.$store.commit("setMessage", `please input customer info`);
                    return;
                }

                //check item length
                if (!this.order.items.length) {
                    this.$store.commit("setMessage", `please add at least 1 item`);
                    return;
                }

                //check item complete
                var badmsg = "";
                var bad = this.order.items.find(item => {
                    if (!item.product._id || !item.variant._id || !item.variant.price || !item.quantity || !item.rentalStartDate) {
                        return true;
                    }
                    if (item.product.rental && !item.rentalEndDate) {
                        return true;
                    }
                    if (item.product.rental && item.rentalEndDate <= item.rentalStartDate) {
                        badmsg = "end date must be bigger then start date";
                        return true;
                    }

                    return false;
                });

                if (bad) {
                    this.$store.commit("setMessage", badmsg || `please complete editing item info`);
                    return;
                }

                if (!this.order.address) {
                    this.$store.commit("setMessage", `please complete editing address info`);
                    return;
                }

                if (!this.order.staff_id) {
                    this.order.staff_id = null;
                }

                if (this.id) {
                    let result = await this.$store.dispatch("crud/put", {
                        api: `orders/${this.id}`,
                        data: {
                            ...this.order
                        }
                    });
                    this.$store.commit("setMessage", "Updated Successfully");
                } else {
                    this.order.admin = true;
                    let vendor_name = this.order.items[0].product.vendor_name;
                    this.order.pickups = { [vendor_name]: this.order.pickup };

                    let result = await this.$store.dispatch("crud/post", {
                        api: `orders`,
                        data: JSON.parse(JSON.stringify(this.order))
                    });

                    window.gtagEventConversion && window.gtagEventConversion();

                    this.$store.commit("setMessage", "Saved Successfully");
                    if (result.length) {
                        var id = result[0]._id;
                        this.$router.push(`/admin/orders/editDetail/${id}`);
                    }
                }

                if (this.order && this.order.status == "Void") {
                    this.$router.push("/admin/orders/manage");
                } else {
                    this.getOrder();
                }
            }
        },
        confirmStatusChange() {
            if (this.order.status == "Void") {
                let content = `Are you sure to change the order status to ${this.order.status} ? It will delete this booking.`;
                this.$refs["status-confirm"].open("Status Change", content);
            } else {
                this.save();
            }
        },
        handleUserChanged(user) {
            console.info("handle user changed", user);
            if (user) {
                this.order.user_email = user.email;
                this.order.user_name = user.name;
                this.order.user_company = user.company;
                this.order.user_id = user._id;
            } else {
                this.order.user_name = undefined;
                this.order.user_company = undefined;
                // this.order.user_id = undefined;
            }
            this.matchUserAddress(user);
        },

        handleUserEmailChanged(email) {
            // used
            this.order.user_email = email;
        },

        goBack() {
            this.$router.go(-1);
        }
    },
    mounted() {
        this.getPays();
        this.getOrder().then(() => this.loadOffHourFee());
        this.getUsers();
    }
};
</script>
