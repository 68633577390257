<template>
    <div class="order-items-edit">
        <md-table-card style="width:100%;">
            <md-table>
                <md-table-header>
                    <md-table-row>
                        <md-table-head style="width:25%">name</md-table-head>
                        <md-table-head>price</md-table-head>
                        <md-table-head>quantity</md-table-head>
                        <md-table-head>StartDate</md-table-head>
                        <md-table-head>EndDate</md-table-head>
                        <md-table-head v-if="false">rentalDeposit</md-table-head>
                        <md-table-head>AddOnItem</md-table-head>
                        <md-table-head>delete</md-table-head>
                    </md-table-row>
                </md-table-header>
                <md-table-body>
                    <md-table-row :key="ix" v-for="(item,ix) in items">
                        <md-table-cell>
                            <router-link :to="'/'+item.product.slug+'/'+item._id" v-if="!item.isNew">
                                <span>{{item.product.name}} / {{item.variant.name}}</span>
                            </router-link>
                            <md-input-container v-if="item.isNew">
                                <md-autocomplete :debounce="200" :filter-list="nameFilter" :list="variants" @selected="setItem(item,$event)" print-attribute="disname" v-model="item.disname"></md-autocomplete>
                            </md-input-container>
                        </md-table-cell>
                        <md-table-cell>
                            <md-input-container>
                                <md-input v-model="item.variant.price"></md-input>
                            </md-input-container>
                        </md-table-cell>
                        <md-table-cell>
                            <md-input-container>
                                <md-input v-model="item.quantity"></md-input>
                            </md-input-container>
                        </md-table-cell>
                        <md-table-cell>
                            <date-time-picker :limit="[]" mode="block" v-if="!item.product.rental" v-model="item.rentalStartDate" valueFormat="moment"></date-time-picker>
                            <date-time-picker :limit="[]" v-if="item.product.rental" v-model="item.rentalStartDate" valueFormat="moment"></date-time-picker>
                        </md-table-cell>
                        <md-table-cell>
                            <date-time-picker :limit="[]" v-if="item.product.rental" v-model="item.rentalEndDate" valueFormat="moment"></date-time-picker>
                        </md-table-cell>
                        <md-table-cell v-if="false">
                            <md-input-container v-if="item.product.rental">
                                <md-input v-model="item.rentalDeposit"></md-input>
                            </md-input-container>
                        </md-table-cell>
                        <md-table-cell>
                            <md-button @click="$refs['addOnItemsModal'].open(item)" class="md-icon-button" type="button">
                                <md-icon>edit</md-icon>
                            </md-button>
                        </md-table-cell>
                        <md-table-cell>
                            <md-button @click="removeItem(item)" class="md-icon-button" type="button">
                                <md-icon>delete</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                </md-table-body>
            </md-table>
            <md-button @click="addItem" class="md-primary">Add another item</md-button>
        </md-table-card>
        <add-on-items-modal ref="addOnItemsModal"></add-on-items-modal>
    </div>
</template>
<script>
import DateTimePicker from "@/components/DateTimePicker";
import AddOnItemsModal from "@/components/modal/AddOnItemsModal";
import moment from "moment-timezone";
export default {
    props: ["items", "vendor"],
    data() {
        return {
            products: [],
            variants: []
        };
    },
    components: {
        DateTimePicker,
        AddOnItemsModal
    },
    methods: {
        removeItem(item) {
            var index = this.items.indexOf(item);
            this.items.splice(index, 1);
        },
        addItem() {
            this.items.push({ isNew: true, product: {}, variant: {} });
            console.info(this.items);
        },
        setItem(item, v) {
            console.info(v);
            let { product, variant, disname } = v;
            let { price } = item.variant;
            // variant.price = price;

            this.$set(item, "product", product);
            this.$set(item, "variant", variant);
            this.$set(item, "addOnItems", product.addOnItems);
            this.$set(item, "_id", product._id);
            this.$set(item, "vendor_id", product.vendor_id);
            this.$set(item, "rental", product.rental);
            this.$set(item, "vendor_id", product.vendor_id);
            this.$set(item, "rental", product.rental);
            this.$set(item, "vid", variant._id);
            this.$set(item, "quantity", 1);

            this.$set(item, "disname", disname);

            //fix bug of dropdown not refresh
            this.$set(item, "rentalStartDate", moment().add(1, "hour"));
            setTimeout(() => {
                this.$set(item, "rentalStartDate", moment());
            }, 0);

            product.rental && this.$set(item, "rentalEndDate", moment().add(1, "hour"));
        },
        nameFilter(list, query) {
            if (query) {
                let pname = query.split("/")[0];
                let vname = query.split("/")[1];
                let preg = pname && new RegExp(pname.trim(), "i");
                let vreg = vname && new RegExp(vname.trim(), "i");

                let arr = list
                    .filter(item => !this.items.find(i => i.variant._id == item.variant._id))
                    .filter(item => {
                        if (preg && vreg) {
                            return preg.test(item.product.name) && vreg.test(item.variant.name);
                        }
                        if (preg && !vreg) {
                            return preg.test(item.product.name) || preg.test(item.variant.name);
                        }
                        return false;
                    });
                return arr;
            }
            return [];
        },
        async getMyProduct() {
            var result = await this.$store.dispatch("crud/get", {
                api: "products/allByVendor/" + (this.vendor || "")
            });
            this.products = result;
            this.variants = result.reduce((acc, p) => {
                var vs = p.variants.map(v => ({
                    product: p,
                    variant: v,
                    disname: p.name + "/" + (v.name || "")
                }));
                return acc.concat(vs);
            }, []);
        }
    },
    created() {
        this.getMyProduct();
    }
};
</script>
<style lang="less">
.order-items-edit {
    min-width: 1200px;
}
</style>
